<template>
  <v-container class="fill-height grey lighten-4">
    <v-row align="center">
      <v-col class="align" cols="12">
        <v-card max-width="400" class="mx-auto">
          <v-row justify="end" class="pr-5">
            <router-link :to="{ name: 'tomato7Home' }">
              <v-btn icon color="blue-grey"> X </v-btn>
            </router-link>
          </v-row>
          <v-card-title primary-title class="justify-center pt-10"
            >U+ 사장님광장 가입자 인증</v-card-title
          >
          <v-card-text class="pt-1">
            <v-spacer class="pt-10"></v-spacer>
            <div class="text-subtitle-1 pl-6">가입 인증 번호</div>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="mSUB_NO"
                :rules="SUBNOrules"
                rounded
                outlined
                dense
                clearable
                required
                hide-details="false"
              />
            </v-form>
            <v-row>
              <v-col cols="1">
                <v-icon small color="green darken-2"> mdi-compass-rose </v-icon>
              </v-col>
              <v-col cols="10" class="pl-1">
                U+ 사장님광장에서 자동 복사된 [가입인증번호]를 [붙여넣기] 해
                주세요
              </v-col>
            </v-row>
          </v-card-text>
          <v-spacer class="pt-10"></v-spacer>

          <v-card-actions class="pt-10 pb-10">
            <v-btn color="success" block @click="validate"> 인증 요청 </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <dialog-msg />
  </v-container>
</template>
<script>
import EventBus from "./EventBus";
import DialogMsg from "./dialogMessage.vue";
import { mapActions } from "vuex";
const casezi = "casezi";

export default {
  name: "Successplaza",
  components: {
    DialogMsg,
  },
  data() {
    return {
      dialog: false,
      dialogHead: "",
      dialogMessage: "",
      mCPID: "52000025369",
      mSUB_NO: "",
      valid: false,
      SUBNOrules: [(value) => !!value || "필수 입력 항목입니다."],
    };
  },
  computed: {},
  methods: {
    ...mapActions(casezi, ["REQU_CASEZI"]),
    validate() {
      if (this.$refs.form.validate()) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      try {
        const Result = await this.REQU_CASEZI({
          fun_code: "casezi",
          cas_id: this.mCPID,
          sub_no: this.mSUB_NO,
        });
        if (Result) {
          console.log("로그인 결과", Result);
          if (Result.data.repCode == "00" && Result.data.SvcAuth == "1") {
            this.goToPages(); //페이지 이동
          } else if (
            Result.data.repCode == "00" &&
            Result.data.SvcAuth == "0"
          ) {
            this.dialogHead = "info";
            this.dialogDesc = true;
            this.dialogMessage =
              "MMS/카카오톡 알림톡으로 수신받은 고객 가입번호를 다시 확인 해 주세요 .";
            this.clickBtn();
          } else {
            this.dialogHead = "info";
            this.dialogDesc = false;
            this.dialogMessage =
              "MMS 문자로 수신받은 고객 가입번호를 확인 해 주세요 .";
            this.clickBtn();
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    clickBtn() {
      let payload = {
        Head: this.dialogHead,
        Message: this.dialogMessage,
        Desc: this.dialogDesc,
      };
      EventBus.$emit("push-msg", payload);
    },
    goToPages() {
      this.$router.push({
        name: "successpack",
      });
    },
  },
};
</script>
<style scoped></style>
